import { Box, Button, Grommet, Heading, Image } from 'grommet'
import { customTheme } from './theme/base.theme'
import { Apple, FacebookOption, Instagram, Spotify } from 'grommet-icons'
import logo from './images/cellophanesuckers-logo.svg'
import image from './images/suckers-cover-2019.png'
import React from 'react'

function App () {

    const Longplayer = () => <Box pad={'medium'} height="medium" width="medium"><Image
        src={image}
        fit={'contain'}
    /></Box>

    const buyLink = 'https://www.soundflat.de/punkrock/punkrock-lp/cellophane-suckers-ghost-rider-brd-lp'
    const appleLink = 'https://geo.music.apple.com/us/artist/cellophane-suckers/401964741?mt=1&app=music'
    const spotifyLink = 'https://open.spotify.com/artist/1XI5VOpu5lG2k1tYcKvlVY'
    const facebookLink = 'https://www.facebook.com/cellsuck/'
    const instagramLink = 'https://www.instagram.com/cellophanesuckers'

    return (
        <Grommet className="App" theme={customTheme} css={{ background: 'black' }} full responsive={false}>
            <Box padding={'xsmall'} margin={{ 'horizontal': 'auto', 'vertical': 'xlarge' }} align={'center'}>
                <Box pad={'medium'} width="large">
                    <Image src={logo}/>
                </Box>
                <Heading textAlign={'center'}>We're back soon!</Heading>
                <Box margin={'xlarge'} align={'center'} gap={'small'}>
                    <Heading level={3}>
                        Record out now:
                    </Heading>
                    <Box gap={'large'} align={'center'} pad={'xsmall'}>
                        <Button plain fill label={<Longplayer/>} href={buyLink}/>
                        <Button label={'Buy'} href={buyLink}/>
                        <Box direction={'row'}>
                            <Button icon={<Apple/>} href={appleLink}/>
                            <Button icon={<Spotify/>} href={spotifyLink}/>
                            <Button icon={<FacebookOption/>} href={facebookLink}/>
                            <Button icon={<Instagram/>} href={instagramLink}/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Grommet>
    )
}

export default App
